import AccountProcessStep0002 from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/12306-account-process-step/12306-account-process-step-0002/2.0.0/index.vue'

export default {
  data() {
    return {
      backDialogVisible: false,
    }
  },
  components: {
    AccountProcessStep0002
  },
  activated() {
    this.$refs['accountProcessStep0002'].init({
      situation: '000', // 000:客户端 100:平台端
    })
  },
  methods: {
    addNewAccount() {
      this.$router.push({
        name: 'admin-train-account-depute'
      });
    },
    checkResult(result) {
      switch (result.checkCode) {
        case '000000':
          this.$message.success(result.checkMsg);
          // this.back();
            this.$router.replace({
              name: 'admin-my-information'
            })
          break;
        default:
          this.$message.warning(result.checkMsg);
          break
      }
    },
    beforeBack() {
      this.backDialogVisible = true;
    },
    back() {
      this.backDialogVisible = false;
      this.$router.go(-1);
    }
  },
};
